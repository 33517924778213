import http from './axios';

class InvoiceService {
  getInvoices() {
    return http.get('/invoices');
  }

  paidOutsideStripe(invoiceId) {
    return http.patch(`/invoices/${invoiceId}`);
  }
}

export default new InvoiceService();

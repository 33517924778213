export default {
  "admin": {
    "allTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allzeit"])},
    "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AP"])},
    "config": {
      "canChangeWifiSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WLAN-Einstellungen ändern?"])},
      "confirm": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie diese Konfiguration löschen möchten?"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguration löschen"])}
      },
      "omada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie eine Omada-Datei."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguration"])},
      "titleModal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguration hinzufügen"])},
      "unifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie eine Unifi-Datei"])}
    },
    "controllers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller"])},
    "customer": {
      "addCustomerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden"])},
      "addCustomersTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde hinzufügen"])},
      "addLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort erstellen"])},
      "addLocationsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorte"])},
      "editDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details bearbeiten"])},
      "inviteUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
      "linkStripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Stripe verbinden"])},
      "locationUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort unbekannt"])},
      "manageLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links verwalten"])},
      "manageUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer verwalten"])},
      "newLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der erstellte Standort hat dieselbe Adresse wie die beim Kunden eingegebene"])},
      "noCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Kunden gefunden"])},
      "noInvites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Einladungen"])},
      "noLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Standorte gefunden"])},
      "noUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Benutzer"])},
      "selectCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden auswählen"])},
      "selectCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde auswählen"])},
      "selectExistingCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestehenden Kunden wählen"])},
      "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort auswählen"])},
      "selectPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan auswählen"])},
      "selectProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbieter auswählen"])},
      "stripeLinked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Stripe verbunden"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden"])},
      "selectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorttyp auswählen"])},
      "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie dies löschen möchten?"])},
      "freeTier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenlose Stufe"])},
      "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan"])},
      "selectCaas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie, ob dieser Standort CaaS ist"])},
      "caas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectivity as a Service (CaaS)"])},
      "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demomodus aktivieren (z.B. PoC)"])},
      "accountAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwenden Sie die Rechnungs-E-Mail-Adresse für das Konto"])},
      "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto für amplr.cloud erstellen"])},
      "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindungstyp auswählen"])}
    },
    "dashboard": {
      "allGood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles in Ordnung"])},
      "controllers": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller"])}
      },
      "locations": {
        "newLocationsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereit zur Konfiguration"])}
      },
      "portal": {
        "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal"])}
      }
    },
    "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geräte"])},
    "gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway"])},
    "installLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereit zur Installation"])},
    "location": {
      "chooseConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurationstyp auswählen"])},
      "chooseControllers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzwerkcontroller auswählen"])},
      "chooseHardwareVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die verschiedenen Gerätehersteller"])},
      "chooseOmadaController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omada-Controller auswählen"])},
      "choosePortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorhandenes Portal auswählen"])},
      "chooseUnifiController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unifi-Controller auswählen"])},
      "config": {
        "startConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginnen Sie mit der Konfiguration der Controller"])},
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurationstyp"])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Router"])},
        "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch"])},
        "step4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Point"])},
        "wifiConfigDisabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Konfiguration erlaubt es nicht, die WLAN-Namen oder Passwörter zu ändern. Bitte fahren Sie ohne fort."])},
        "step5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drahtlose Netzwerke"])},
        "step6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht"])}
      },
      "confirm": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie diesen Standort archivieren möchten? Achtung: Die Live-Daten werden ebenfalls archiviert"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort archivieren/pausieren"])}
      },
      "copyPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorhandene Portal-Einstellungen kopieren"])},
      "createNewLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Standort für dieses Unternehmen erstellen"])},
      "createPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal erstellen"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standortdetails"])},
      "existingPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorhandenes Portal"])},
      "finish": {
        "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Points"])},
        "chooseEnclosure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welches Gehäuse wurde verwendet?"])},
        "discoveredDevices": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Geräte sind an diesem Standort aktiv und werden in den Arbeitsauftrag aufgenommen."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwendete Geräte"])}
        },
        "gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Router"])},
        "noEnclosure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Patchkasten"])},
        "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switches"])},
        "chooseAccessoires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche Materialien wurden zur Fertigstellung verwendet"])},
        "chooseAccessoire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt auswählen"])}
      },
      "finishConfig": {
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation validieren"])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnung validieren"])},
        "addInstaller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installateur hinzufügen"])}
      },
      "label": {
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])},
        "apVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AP"])},
        "gatewayVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway"])},
        "switchVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch(es)"])}
      },
      "peplinkSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink-Einstellungen wählen"])},
      "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captive-Portal erstellen"])},
      "selectApVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde auswählen"])},
      "selectCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde auswählen"])},
      "selectConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurationstyp auswählen"])},
      "selectCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden auswählen"])},
      "selectGatewayVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Router-Hersteller auswählen"])},
      "selectOmadaController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omada-Controller wählen"])},
      "selectPeplinkController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink-Standort wählen"])},
      "selectPeplinkOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink-Organisation wählen"])},
      "selectPeplinkTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink-Vorlagendatei auswählen"])},
      "selectSwitchVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch-Hersteller auswählen"])},
      "selectUnifiController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unifi-Controller wählen"])},
      "unknownCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannt"])},
      "updateParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter aktualisieren"])},
      "noInstallionLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt noch kein Installationsprotokoll für diesen Standort."])},
      "addInstallationLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installationsprotokoll hinzufügen"])},
      "configReport": {
        "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurationstyp"])},
        "sim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sim vorhanden"])},
        "internetType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internettyp"])},
        "fritz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fritz!Box vorhanden"])},
        "vlans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VLANs"])},
        "option138": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option138"])},
        "option43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option43"])},
        "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WLAN-Check"])},
        "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastportal"])},
        "gatewayVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie den Gateway-Anbieter"])},
        "apVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie den AP-Anbieter"])},
        "switchVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie den Switch-Anbieter"])},
        "devicesAdopted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geräte adoptiert"])},
        "sitesLinked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorte erstellt und verknüpft"])},
        "pppoe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PPPOE"])}
      },
      "addConfigurationLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurationsbericht hinzufügen"])},
      "noConfigurationLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt noch keinen Konfigurationsbericht für diesen Standort"])},
      "noComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt noch keine Kommentare"])},
      "chooseGateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie den Hersteller und Controller für den Router"])},
      "selectVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hersteller auswählen"])},
      "chooseSwitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie den Hersteller und Controller für den Switch"])},
      "chooseAp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie den Hersteller und Controller für den Access Point"])},
      "diyInstallation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kunde wird die Installation selbst durchführen"])},
      "exportConfigurationLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurationsbericht exportieren"])},
      "exportInstallationLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installationsprotokoll exportieren"])},
      "exportLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht exportieren"])},
      "noWlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wird kein Hersteller für Zugangspunkte angegeben. Drahtlose Netzwerke können nicht geladen werden. Bitte fahren Sie fort."])},
      "changeLocationStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist eine DIY-Installation"])},
      "changeLocationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Name des Standorts unterscheidet sich vom Firmennamen"])},
      "changeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwenden Sie einen Standortnamen anstelle des Firmennamens. Nur verwenden, wenn der Standortname vom Firmennamen abweicht."])},
      "restoreConfirm": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort wiederherstellen"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellen Sie einen Standort wieder her. Der Standort wird für den Kunden wieder sichtbar."])}
      },
      "subscription": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie die Abonnement-ID von Stripe hinzu, um die Details des Standortabonnements anzuzeigen."])}
      },
      "vendors": {
        "modal": {
          "peplink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Peplink"])},
          "omada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link TP-Link Omada"])},
          "unifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Unifi"])},
          "zerotier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Zerotier"])}
        },
        "peplink": {
          "currentId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle verknüpfte ID"])},
          "newId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu ausgewählte ID"])}
        }
      },
      "selectPeplinkInstance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die Peplink-Instanz aus"])},
      "choosePeplinkController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink-Instanz"])},
      "choosePeplinkOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink-Organisation"])},
      "selectPeplinkGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die Peplink-Gruppe aus"])},
      "choosePeplinkGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink-Gruppe"])},
      "selectOmadaSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die Omada-Site aus"])},
      "chooseOmadaSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omada-Website"])},
      "chooseVendorIds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzwerkanbieter"])},
      "ppsk": {
        "currentId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PPSK-Profil-ID"])},
        "currentSSID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit PPSK verknüpfte SSID"])}
      },
      "selectPpskProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie das PPSK-Profil aus"])},
      "ppskProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PPSK-Profil"])},
      "selectWlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie SSID"])},
      "ppskSsid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSID"])},
      "chooseZerotierNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen Zerotier-Knoten aus"])},
      "selectZerotierNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie den Zerotier-Knoten"])},
      "caasHardware": {
        "modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CaaS-Hardware"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als CSV exportieren"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugeordnete Produkte für diesen Standort"])}
      }
    },
    "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorte"])},
    "newLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereit zur Konfiguration"])},
    "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal"])},
    "switches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switches"])},
    "table": {
      "config": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
        "controllerUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
        "lastUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt aktualisiert"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
        "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbieter"])},
        "controllerVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller-Version"])},
        "authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisiert"])},
        "nodeId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zerotier-ID"])},
        "nodeIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knoten-IP"])},
        "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])}
      },
      "customer": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
        "alias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Kundenname suchen"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
        "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MwSt."])},
        "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Keine Kunden gefunden.</p><p>Versuchen Sie es mit angepassten Filtern.</p>"])}
      },
      "location": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
        "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Point(s)"])},
        "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt am"])},
        "customerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])},
        "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Keine Standorte gefunden.</p><p>Versuchen Sie es mit angepassten Filtern.</p>"])},
        "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastportal"])},
        "router": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Router"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Kunde oder Standort suchen"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch(es)"])},
        "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
        "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modell"])},
        "configReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurationsbericht erstellen"])},
        "startConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguration starten"])},
        "venue_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veranstaltungsortname"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
        "startInstallation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installationsbericht erstellen"])},
        "qrImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-Bild"])},
        "qrLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-Etikett"])},
        "printLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etikett drucken"])},
        "viewConfigReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurationsbericht anzeigen"])},
        "viewInstallReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installationsbericht anzeigen"])},
        "locationDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort anzeigen"])},
        "viewBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnung anzeigen"])},
        "snooze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlummern"])},
        "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsmethode"])},
        "getCaasHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CaaS-Hardware auflisten"])}
      },
      "partners": {
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Partnername suchen"])}
      },
      "webhook": {
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
        "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ereignis"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
      },
      "sales": {
        "hunterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jäger"])},
        "venueType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veranstaltungstyp"])},
        "venueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veranstaltungsortname"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
        "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
        "wantsOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchte Angebot"])},
        "follow_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benötigt Nachverfolgung"])},
        "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt am"])}
      }
    },
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heute"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
    "wlan": {
      "addRandomGeneratedPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zufallspasswort generieren"])},
      "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privates Netzwerk"])},
      "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffentliches Netzwerk"])}
    },
    "partners": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner"])},
      "addModal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner hinzufügen"])}
      },
      "addAccessModal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugang hinzufügen"])}
      }
    },
    "partner": {
      "confirm": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner löschen"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie diesen Partner löschen möchten?"])}
      }
    },
    "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiviert"])},
    "sales": {
      "totalVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Jäger heute"])},
      "yourVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Besuche"])},
      "allTimeVisits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besuche insgesamt"])},
      "calc": {
        "oneOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einmalige Gesamtkosten"])},
        "mrr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlich wiederkehrender Umsatz"])},
        "amplrSetup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMPLR.setup"])},
        "hardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware"])},
        "hardwareCaas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware-CaaS-MRR"])},
        "servicesMrr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistungen CaaS MRR"])}
      },
      "configCalc": {
        "devices": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geräte"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte für das Angebot hinzufügen"])}
        },
        "connections": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internetverbindungen"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die gewünschten Internetverbindungen"])}
        },
        "plan": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviceplan"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie den gewünschten SLA-Plan für die Berechnung"])}
        },
        "installCat": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installationskategorie"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die Installationskategorie basierend auf den Installationsschätzungen"])}
        },
        "amountVenues": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Veranstaltungsorte"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die Anzahl der Veranstaltungsorte ein"])}
        },
        "addDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geräte durch Suchen und Auswählen hinzufügen."])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechnungsdetails"])},
        "calculation": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechnung"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaillierte Übersicht der Berechnung"])}
        }
      },
      "update": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisierung erfolgreich gespeichert"])}
      },
      "totalMrr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt-MRR"])},
      "totalOneOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtkosten einmalig"])}
    },
    "page": {
      "sales": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertriebsjäger"])}
      },
      "inventory": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventar"])}
      },
      "products": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMPLR Produkte"])}
      },
      "allocated": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugeordneter CaaS"])}
      }
    },
    "statusModal": {
      "controllers": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloudcontroller"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnet einen neuen Tab mit der Controller-Oberfläche für diesen Standort"])}
      }
    },
    "snoozed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlummernd"])},
    "customers": {
      "table": {
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])}
      }
    },
    "payments": {
      "create": {
        "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA (Serviceplan)"])},
        "placeholder": {
          "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA wählen"])},
          "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internetverbindung wählen"])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["999.99"])}
        },
        "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internetverbindung"])},
        "hasFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Failover"])},
        "hasPeplink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Peplink-Router"])},
        "internetPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet noch nicht installiert"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe-Preis erstellen"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den Betrag für das Abonnement an"])},
        "hardwarePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den Hardwarepreis an"])},
        "caasDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erkannte Geräte"])},
        "removedCaasDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht AMPLR-Geräte"])}
      }
    },
    "inventory": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "ean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAN"])},
      "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
      "purchasePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufpreis"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtwert"])},
      "stripeLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe"])},
      "productNotFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Produkt mit EAN: ", _interpolate(_named("ean")), " nicht gefunden"])},
      "editProduct": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte zum Inventar hinzufügen"])}
      },
      "addInventory": {
        "productsToAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt, das zum Inventar hinzugefügt wird"])},
        "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Inventar hinzufügen"])}
      },
      "addTnventory": {
        "noProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Produkte hinzuzufügen."])}
      },
      "addProduct": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Produktreferenz hinzufügen"])}
      },
      "form": {
        "label": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktname des Anbieters"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkttyp"])},
          "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modell (z.B. SG2008P)"])},
          "ean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAN/SKU"])},
          "purchasePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufpreis"])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufspreis"])},
          "caasPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CaaS-Preis"])},
          "configPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurationspreis, falls zutreffend"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SG2008P"])},
          "amplrName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMPLR-Produktname"])},
          "supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferanten auswählen"])},
          "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbieter"])},
          "licencePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzlicher monatlicher Lizenzpreis"])}
        },
        "placeholder": {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkttyp auswählen"])},
          "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbieter"])},
          "supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferant"])}
        },
        "name": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink B ONE 5G"])}
        }
      },
      "deductInventory": {
        "productsToDeduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte, die abgezogen werden"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte abziehen"])}
      },
      "deductProducts": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte aus dem Inventar abziehen"])}
      },
      "amplrProductSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMPLR-bezogenes Produkt auswählen"])},
      "amplrProductPlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt auswählen..."])},
      "createProduct": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Inventarprodukt erstellen"])}
      },
      "updateProduct": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventarprodukt aktualisieren"])}
      },
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Inventarprodukt erstellen"])},
      "product": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMPLR-Produkt erstellen"])}
      },
      "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "productModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktmodell/Beschreibung"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht"])},
      "noModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Modelle"])},
      "models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelle"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt aktualisieren"])}
    },
    "products": {
      "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt auswählen"])}
    },
    "zerotier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zerotier-Knoten"])},
    "zerotierNodes": {
      "authorize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisieren"])},
      "authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisiert"])}
    },
    "allocated": {
      "returnModal": {
        "listHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugeordnete Produkte"])},
        "lostHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlorene Hardware (Rechnung erforderlich)"])},
        "archiveLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivspeicherort"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CaaS-Hardware zurückgeben"])}
      },
      "buttons": {
        "lostBroken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verloren/kaputt"])}
      },
      "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine zugeordneten Produkte gefunden, Standort auswählen oder wechseln."])},
      "nothingToReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sind keine Produkte als verloren oder kaputt gekennzeichnet."])}
    },
    "amplrProduct": {
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie ein Verstärkerprodukt"])}
    },
    "suppliers": {
      "selectSupplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen Lieferanten aus"])}
    }
  },
  "auth": {
    "login": {
      "alreadymember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schon Mitglied?"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
      "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
      "forgotPasswordLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen!"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen zurück!"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihre Anmeldeinformationen ein, um auf Ihr Konto zuzugreifen"])},
      "logginIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden..."])},
      "titleMorning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guten Morgen!"])},
      "titleAfternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guten Nachmittag!"])},
      "titleEvening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guten Abend!"])},
      "body2Fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihren Zwei-Faktor-Authentifizierungscode ein, um auf amplr.cloud zuzugreifen"])},
      "title2Fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifizieren Sie Ihr Konto"])}
    },
    "recover": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen? Setzen Sie Ihr Passwort mit einem Zurücksetzungslink zurück."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link senden"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederherstellen"])}
    },
    "register": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie ein Konto für amplr.cloud."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
      "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie Ihre Sprache"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung erfolgreich"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])}
    },
    "reset": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie ein neues Passwort, um sich bei amplr.cloud anzumelden"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort speichern"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern Sie Ihr Passwort"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwalten Sie Ihre Konnektivität"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMPLR.cloud ist für Sie gemacht. Wir möchten, dass Sie die Kontrolle über Ihr Internet und WLAN übernehmen. Erhalten Sie Einblicke, ändern Sie Einstellungen, erstellen Sie schöne Gastportale und vieles mehr."])}
  },
  "button": {
    "admin": {
      "addCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde hinzufügen"])},
      "addConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguration hinzufügen"])},
      "addCustomersToGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde hinzufügen"])},
      "addLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort hinzufügen"])},
      "addLocationsCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenstandort hinzufügen"])},
      "finishInstallation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertigstellen"])},
      "inviteUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer einladen"])},
      "printPublicLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etikett drucken"])},
      "selectCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestehenden Kunden hinzufügen"])},
      "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestehenden Standort hinzufügen"])},
      "startConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguration starten"])},
      "addLocationReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standortbericht hinzufügen"])},
      "locationReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standortbericht"])},
      "addPartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner hinzufügen"])},
      "addAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugang hinzufügen"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
      "sales": {
        "addVisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besuch hinzufügen"])}
      },
      "addController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller hinzufügen"])},
      "inventory": {
        "addQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte hinzufügen"])},
        "addProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Produkt erstellen"])},
        "deductQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte abziehen"])}
      },
      "allocated": {
        "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CaaS-Hardware zurückgeben"])}
      }
    },
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinden"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen"])},
    "deselectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle abwählen"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entsperren"])},
    "facebookLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Facebook anmelden"])},
    "googleLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Google anmelden"])},
    "linkedinLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit LinkedIn anmelden"])},
    "location": {
      "addDeviceGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Gruppe hinzufügen"])}
    },
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsmethode einrichten"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächster Schritt"])},
    "payments": {
      "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsseite öffnen"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link erstellen"])}
    },
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorheriger Schritt"])},
    "printLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etikett drucken"])},
    "printQr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR drucken"])},
    "rebootNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzwerk neu starten"])},
    "rebootNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt neu starten"])},
    "rebooting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neustart in Bearbeitung"])},
    "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwendung neu laden"])},
    "removeWebhook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook entfernen"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle auswählen"])},
    "showWebhookLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protokoll anzeigen"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einreichen"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansehen"])},
    "webhookResend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook erneut senden"])},
    "webhookSending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden..."])},
    "webhookTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test senden"])},
    "showErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probleme anzeigen"])},
    "customize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpassen"])},
    "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veröffentlichen"])},
    "tenants": {
      "addTenants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mieter hinzufügen"])}
    },
    "createTenants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mieter speichern"])},
    "addMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere hinzufügen"])},
    "deactivateNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt deaktivieren"])},
    "enableTwoFactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA aktivieren"])},
    "disableTwoFactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA deaktivieren"])},
    "2fa": {
      "validating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA-Code wird validiert..."])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code validieren"])}
    },
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieren"])},
    "addComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar hinzufügen"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
    "scheduleDeactivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktivierung planen"])},
    "payOverDue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überfällige Rechnungen bezahlen"])},
    "previewPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastportal-Vorschau"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut senden"])},
    "reloadList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste neu laden"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details anzeigen"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rufen Sie uns an"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederherstellen"])},
    "showCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde anzeigen"])},
    "openStripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe-Konto anzeigen"])},
    "editCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde bearbeiten"])},
    "removeGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählte Gruppe entfernen"])},
    "createSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement erstellen"])},
    "createInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung erstellen"])},
    "showLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort anzeigen"])},
    "salesAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiteren Besuch hinzufügen"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besuche exportieren"])},
    "startAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut starten"])},
    "sales": {
      "configcalculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurationsberechnung"])},
      "editVisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besuch bearbeiten"])},
      "saveNewConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Berechnung speichern"])},
      "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen speichern"])}
    },
    "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen speichern"])},
    "impersonate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imitieren"])},
    "stopImpersonating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imitatormodus beenden"])},
    "checkConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung testen"])},
    "addController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller hinzufügen"])},
    "editController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller bearbeiten"])},
    "portalSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "editClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerät bearbeiten"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
    "newRefferal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiteren hinzufügen"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
    "createNewPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiteren erstellen"])},
    "unlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfung aufheben"])},
    "editLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link bearbeiten"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfung"])},
    "addPpsk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link PPSK"])},
    "deletePpsk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PPSK löschen"])},
    "printZerotierLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drucken Sie das Zerotier-Etikett"])},
    "releaseClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten Sie feste Clients"])},
    "viewGuestData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastdaten ansehen"])}
  },
  "client": {
    "amountDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
    "config": {
      "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alarm"])},
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstehend"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg"])}
    },
    "dhcpInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Festlegen einer festen/statischen IP für ein Gerät stellt sicher, dass das Gerät immer dieselbe IP-Adresse erhält. Das Ändern der Adresse erfordert einen Neustart dieses Geräts oder die erneute Verbindung zum richtigen drahtlosen Netzwerk."])},
    "dropdown": {
      "filterAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])}
    },
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "filter": {
      "allNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Netzwerke"])}
    },
    "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gäste"])},
    "hasStaticIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Gerät hat eine feste IP."])},
    "infoIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client-IP"])},
    "infoName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client-Name"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client-Informationen"])},
    "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
    "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
    "static": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservierte Clients"])},
    "staticIpNotMatched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die aktuelle IP-Adresse stimmt nicht mit der festen IP-Adresse überein. Starten Sie das Gerät neu oder verbinden Sie sich erneut mit dem WLAN"])},
    "table": {
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
      "clientType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerätetyp"])},
      "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hostname"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
      "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbieter"])},
      "signal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signal"])}
    },
    "updateFixedIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feste/statische IP für diesen Client konfigurieren"])},
    "wired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkabelt"])},
    "wireless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drahtlos"])},
    "restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen das Gerät neu starten oder erneut mit dem richtigen drahtlosen Netzwerk verbinden, um die Änderungen zu sehen."])},
    "connectedWith": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ihr Gerät ist derzeit mit ", _interpolate(_named("wlan")), " verbunden, verbinden Sie das Gerät mit ", _interpolate(_named("wlanToConnect"))])},
    "clientErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige Ihrer Clients benötigen Ihre Aufmerksamkeit"])},
    "errorWlan": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ihr Gerät <strong>", _interpolate(_named("device")), "</strong> ist derzeit mit <strong>", _interpolate(_named("old")), "</strong> verbunden. Bitte verbinden Sie es mit <strong>", _interpolate(_named("new")), "</strong>"])},
    "errorIp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das Gerät <strong>", _interpolate(_named("device")), "</strong> hat neue Einstellungen, bitte starten Sie das Gerät neu."])},
    "restartNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben Änderungen an diesem Client vorgenommen. Bitte starten Sie es neu, bevor Sie fortfahren können."])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benötigt Aufmerksamkeit"])},
    "informationStatic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seien Sie vorsichtig beim Ändern der Einstellungen. Das Ändern einer IP/Netzwerks kann große Auswirkungen auf Ihr Netzwerk haben. Wir übernehmen keine Verantwortung für Fehler aufgrund falscher Konfiguration."])},
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen Sie eine gespeicherte IP-Einstellung für einen Client, der derzeit nicht online ist."])},
    "releaseReservations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurierte Clients"])},
    "reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurierte Clients"])}
  },
  "command": {
    "reboot": {
      "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neustartbefehl gegeben, Neustart in Bearbeitung..."])}
    }
  },
  "cookiepopup": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptieren"])},
    "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Durch Klicken auf \"Alle Cookies akzeptieren\" stimmen Sie der Speicherung von Cookies auf Ihrem Gerät zu, um die Navigation auf der Website zu verbessern, die Nutzung der Website zu analysieren und unsere Marketingbemühungen zu unterstützen. Weitere Informationen finden Sie in unserer ", _interpolate(_list(0)), "."])},
    "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
    "modal": {
      "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Cookies akzeptieren"])},
      "centrum": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie Websites besuchen, können sie Informationen in Ihrem Browser speichern oder anfordern. Diese Speicherung ist oft notwendig, um grundlegende Funktionen der Website zu ermöglichen. Die Speicherung kann für Marketing, Analysen und Personalisierung der Website verwendet werden, z. B. zum Speichern Ihrer Präferenzen. Datenschutz ist uns wichtig, daher haben Sie die Möglichkeit, bestimmte Arten der Speicherung zu deaktivieren, die für den grundlegenden Betrieb der Website nicht erforderlich sind. Das Blockieren von Kategorien kann sich auf Ihre Erfahrung auf der Website auswirken."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz-Präferenzzentrum"])}
      },
      "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Cookies ablehnen"])},
      "preferences": {
        "analytics": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Elemente helfen dem Website-Betreiber zu verstehen, wie seine Website funktioniert, wie Besucher mit der Website interagieren und ob es technische Probleme gibt. Diese Art der Speicherung sammelt in der Regel keine Informationen, die einen Besucher identifizieren."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysen"])}
        },
        "default": {
          "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immer aktiv"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Elemente sind notwendig, um die grundlegenden Funktionen der Website zu ermöglichen."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notwendig"])}
        },
        "marketing": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Elemente werden verwendet, um Werbung anzuzeigen, die für Sie und Ihre Interessen relevanter ist. Sie können auch verwendet werden, um die Anzahl der Anzeigen zu begrenzen, die Sie sehen, und die Wirksamkeit von Werbekampagnen zu messen. Werbenetzwerke platzieren sie normalerweise mit Erlaubnis des Website-Betreibers."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigungspräferenzen nach Kategorie verwalten"])},
        "personalisation": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalisierung"])},
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Elemente ermöglichen es der Website, sich an von Ihnen getroffene Entscheidungen zu erinnern (z. B. Ihren Benutzernamen, Ihre Sprache oder die Region, in der Sie sich befinden) und erweiterte, persönlichere Funktionen bereitzustellen. Zum Beispiel kann eine Website Ihnen lokale Wetter- oder Verkehrsinformationen bereitstellen, indem Informationen über Ihren aktuellen Standort gespeichert werden."])}
        }
      },
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Präferenzen bestätigen und schließen"])}
    },
    "preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Präferenzen"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz-Bestimmungen"])}
  },
  "customer": {
    "address": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Kundenadresse"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])}
    },
    "invoices": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Übersicht Ihrer Rechnungen"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung herunterladen"])},
      "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Keine Rechnungen gefunden.</p>"])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlen"])},
      "table": {
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
        "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsnummer"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsdatum"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsstatus"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fälliger Betrag"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungen"])}
    },
    "payment": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Übersicht Ihrer Zahlungsmethoden, aktuellen Abonnements und Rechnungen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwalten Sie Ihre Zahlungen"])}
    },
    "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmen"])},
    "profile": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Übersicht Ihrer Kundendaten"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])}
    },
    "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
    "logo": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie Ihr Logo hoch"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Logo"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie Ihr Logo hoch, damit wir einige Seiten mit Ihrem Logo anpassen können"])}
    },
    "info": {
      "accountLocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben überfällige Rechnungen. Wenn diese unbezahlt bleiben, werden wir gezwungen sein, rechtliche Schritte einzuleiten. Wenn Sie Fragen zu dieser Nachricht haben, kontaktieren Sie uns bitte unter +32 3 375 60 70."])},
      "lastWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtige Warnung"])}
    },
    "plan": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Plan des Kunden"])}
    },
    "stripe": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnung"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkzeuge zur Abrechnung"])},
      "paymentPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generieren Sie eine Zahlungsportal-URL für den Kunden"])},
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenkonto in Stripe eröffnen"])}
    },
    "create": {
      "header": {
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adressdetails"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmendetails"])},
        "invoicingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsdetails"])},
        "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
        "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardplan"])},
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto erstellen"])}
      },
      "accountWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie die Option Konto erstellen deaktivieren, wird kein Konto erstellt. Es wird auch nicht mit einem bestehenden Benutzer/Konto verknüpft. Wenn Sie es mit einem bestehenden Benutzer verknüpfen möchten, aktivieren Sie es und geben Sie die E-Mail-Adresse des registrierten Benutzers ein."])}
    },
    "paymentMethod": {
      "toggleMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Zahlungsmethode (Check Stripe)"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsart"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setzen Sie das Zahlungsarten-Flag manuell."])}
    }
  },
  "dashboard": {
    "details": {
      "downloadQr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-Code herunterladen"])}
    },
    "devices": {
      "noap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein drahtloses Netzwerk gefunden"])},
      "nogateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Gateway gefunden"])},
      "noswitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Switch gefunden"])}
    },
    "invoices": {
      "noResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Rechnungen sind bezahlt."])}
    },
    "location": {
      "notInstalled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch nicht installiert"])}
    },
    "locations": {
      "allLocationsInstalled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Netzwerke an allen Standorten installiert."])},
      "noResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Netzwerke an allen Standorten sind in Ordnung."])}
    },
    "notification": {
      "allSystemsDown": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ihr Amplr-Netzwerk ist offline. Haben Sie Strom? Ja? Bitte rufen Sie <span class=\"has-text-weight-bold\">", _interpolate(_named("provider")), "</span> an unter <a href=\"tel:", _interpolate(_named("phone")), "\">", _interpolate(_named("phone")), "</a>!"])},
      "allSystemsRunning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ihr Amplr-Netzwerk ist vollständig betriebsbereit. Ihre Internetverbindung wird bereitgestellt von <span class=\"has-text-weight-bold\">", _interpolate(_named("providerName")), "</span>."])},
      "degradedSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Teil Ihres Netzwerks ist nicht betriebsbereit."])},
      "failOverActive": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Failover ist aktiv. Das bedeutet, dass nur Kassendatenverkehr, Zahlungen und Spotify verwendet werden können. Alle Gastnetzwerke oder anderen Netzwerke sind vorübergehend nicht verfügbar. Ihre Verbindung basiert auf SIM und ist mit dem Netzwerk von ", _interpolate(_named("carrier_name")), " verbunden."])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir rufen Informationen von diesem Standort ab."])},
      "readyForInstallation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Standort ist noch nicht einsatzbereit. Wir warten darauf, dass die Installation abgeschlossen wird."])},
      "noHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir konnten keine Hardware in Ihrem System finden."])},
      "powerOutage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<span class=\"has-text-weight-bold\">", _interpolate(_named("source")), "</span> hat einen Stromausfall in Ihrer Region gemeldet. \nDer Ausfall begann am <span class=\"has-text-weight-bold\">", _interpolate(_named("startDate")), "</span> und sollte bis zum <span class=\"has-text-weight-bold\">", _interpolate(_named("endDate")), "</span> andauern \n."])}
    },
    "publicNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist ein öffentliches Netzwerk für Gäste."])},
    "titles": {
      "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drahtlos"])},
      "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbundene Clients"])},
      "deviceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systeme"])},
      "gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway"])},
      "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbezahlte Rechnungen"])},
      "locationDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
      "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorte, die Aufmerksamkeit erfordern"])},
      "locationsReadyForInstall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorte bereit zur Installation"])},
      "privateNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Netzwerke"])},
      "publicNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffentliche Netzwerke"])},
      "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switches"])},
      "wan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet"])}
    },
    "addRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeile hinzufügen"])},
    "financials": {
      "yearToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr bis heute"])},
      "fromTarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Ziel"])},
      "currentMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktueller Monat"])},
      "avgDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro Tag"])},
      "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzter Monat"])},
      "mrr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRR"])}
    },
    "wlan": {
      "ppsk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Netzwerk verwendet PPSK (Private Pre-shared Key). Passwörter können pro Benutzer/Firma im Reiter Mieter eingestellt werden."])}
    },
    "tooltips": {
      "deviceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systemgruppen sind eine Sammlung von netzwerkverbundenen Geräten wie Computern, Lautsprechern, Alarmanlagen, Kameras usw. Erstellen Sie benutzerdefinierte Gruppen, um die Verbindung zu überwachen."])}
    },
    "topology": {
      "gateway": {
        "fixedLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festnetz"])},
        "cellular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4G/5G"])},
        "wanIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffentliche IP"])},
        "virtualWan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtuelles WAN"])}
      }
    },
    "wifi": {
      "privateNetworks": {
        "makeHidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzwerk verstecken"])},
        "tooltip": {
          "makeHidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Verstecken eines Netzwerks führt dazu, dass das WLAN-Netzwerk beim Scannen nicht sichtbar ist. Sie können sich mit einem versteckten Netzwerk verbinden, indem Sie die Netzwerkdetails manuell eingeben."])},
          "broadcast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein sichtbares Netzwerk kann von Geräten beim Scannen nach WLAN-Netzwerken gesehen werden. Wenn Sie dieses Netzwerk verstecken möchten, deaktivieren Sie die Übertragung. Um sich mit einem versteckten Netzwerk zu verbinden, müssen Sie den WLAN-Namen und das Passwort manuell eingeben."])}
        },
        "broadcast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Netzwerk sichtbar machen (Broadcast)"])}
      }
    },
    "privateNetworks": {
      "isHidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstecktes Netzwerk"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WLAN-Einstellungen bearbeiten"])},
      "label": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WLAN-Name"])}
      }
    }
  },
  "datepicker": {
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diesen Monat"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heute"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Jahr"])}
  },
  "error": {
    "401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind nicht berechtigt, diesen Inhalt zu sehen"])},
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht gefunden"])},
    "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gab einen Fehler auf dem Server."])},
    "apiThrottled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben kürzlich bereits einen Zurücksetzungslink angefordert."])},
    "auth": {
      "invalidResetToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Zurücksetzungs-Token ist ungültig"])},
      "sameAsPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Passwörter stimmen nicht überein"])},
      "userNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Konto existiert nicht"])},
      "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort ist nicht korrekt"])}
    },
    "deviceOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerät ist nicht verbunden"])},
    "file": {
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Datei ist zu groß zum Hochladen. Die maximal unterstützte Dateigröße beträgt 500 KB."])}
    },
    "generalError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas ist schief gelaufen."])},
    "image": {
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Bild ist zu groß zum Hochladen. Die maximal unterstützte Dateigröße beträgt 5 MB."])}
    },
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Validierung fehlgeschlagen. Geben Sie eine gültige E-Mail-Adresse ein."])},
    "invalidPublicId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige öffentliche ID"])},
    "messageLoadingLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Laden dauert länger als üblich..."])},
    "noClientsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Clients gefunden."])},
    "noDeviceGroupsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Gerätegruppen gefunden."])},
    "noDevicesFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Geräte gefunden."])},
    "noInvitesFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Einladungen gefunden"])},
    "noPrivateNetworkFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine privaten Netzwerke gefunden"])},
    "noPublicId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine öffentliche ID"])},
    "noPublicNetworkFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine öffentlichen Netzwerke gefunden."])},
    "noSitesFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Standorte gefunden."])},
    "noUserDataFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Daten gefunden."])},
    "noUsersFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Benutzer gefunden"])},
    "portal": {
      "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort ist nicht korrekt."])}
    },
    "portalClient": {
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir konnten Ihre E-Mail-Adresse nicht finden. Das bedeutet, dass wir keine Daten über Sie gespeichert haben."])}
    },
    "sessionExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Sitzung ist abgelaufen. Sie müssen sich erneut anmelden."])},
    "sitesNnone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Standorte gefunden"])},
    "userExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einladungen nicht gesendet.<br/>Folgende Benutzer können nicht eingeladen werden. Entfernen Sie sie aus der Liste und versuchen Sie es erneut."])},
    "value": {
      "isEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Wert darf nicht leer sein"])},
      "notDigits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Wert ist nicht gültig"])},
      "notInRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Wert liegt nicht im Bereich"])}
    },
    "vat": {
      "unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese USt-IdNr. existiert bereits"])},
      "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese USt-IdNr. existiert bereits"])},
      "stripeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe-Fehler: ungültige EU-USt-IdNr. Bitte verwenden Sie eine gültige USt-IdNr."])}
    },
    "noPermissionsSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie mindestens 1 Berechtigung für jede Spalte aus."])},
    "noTenantsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Mieter gefunden"])},
    "tenants": {
      "csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die CSV war nicht im richtigen Format"])}
    },
    "loginError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entschuldigung, Ihr Anmeldeversuch ist fehlgeschlagen. Bitte überprüfen Sie Ihren Benutzernamen und Ihr Passwort und versuchen Sie es erneut."])},
    "2fInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der angegebene Zwei-Faktor-Authentifizierungscode war ungültig."])},
    "config": {
      "configId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie eine Konfiguration"])},
      "choosePeplinkSn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die Peplink-Seriennummer ein"])},
      "chooseController": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen Controller"])},
      "chooseVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen Anbieter"])},
      "wlansName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie alle Namen ein"])},
      "plan": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Basierend auf der Anzahl der Geräte sollten Sie folgenden Plan wählen: ", _interpolate(_named("plan")), " oder höher"])}
    },
    "partners": {
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Partner gefunden"])}
    },
    "noAccessFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Zugriff gefunden"])},
    "noLocationsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Standorte gefunden"])},
    "noGroupsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Gruppen gefunden"])},
    "no": {
      "link": {
        "access": {
          "found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir konnten Sie nicht registrieren, da der von Ihnen verwendete Link nicht mehr gültig ist."])}
        }
      }
    },
    "invalidSubscriptionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige Abonnement-ID."])},
    "subscriptionNotLinkedToCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die angegebene Abonnement-ID ist nicht mit dem Kunden verknüpft, der mit diesem Standort verbunden ist."])}
  },
  "errorPage": {
    "404": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hmmm... diese Seite existiert nicht mehr oder wurde an einen anderen Ort verschoben. Lassen Sie uns Sie zurückbringen!"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht gefunden"])}
    },
    "maintenance": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir entschuldigen uns für die Unannehmlichkeiten. Wir werden bald wieder online sein."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anwendung ist derzeit für Wartungsarbeiten offline."])}
    }
  },
  "form": {
    "field": {
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
      "configName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurationsname"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
      "customerAlias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias"])},
      "deviceGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systemgruppenname"])},
      "downloadSpeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download-Geschwindigkeit"])},
      "downloadSpeedFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download-Geschwindigkeit des Failovers"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
      "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum/-zeit"])},
      "endDatePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum/Zeit wählen"])},
      "freeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentare"])},
      "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-Adresse"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "nameInstaller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Installateurs"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
      "passwordConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort bestätigen"])},
      "passwordNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
      "peplinkOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink-Organisation"])},
      "peplinkSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink-Seriennummer"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
      "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbieter"])},
      "providerid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbieter-ID"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen"])},
      "searchNetworkDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzwerkgerät suchen"])},
      "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum/-zeit"])},
      "startDatePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum/Zeit wählen"])},
      "uploadSpeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload-Geschwindigkeit"])},
      "uploadSpeedFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload-Geschwindigkeit des Failovers"])},
      "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USt-IdNr."])},
      "verifyBeforeDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschriftung oben wiederholen"])},
      "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webseite"])},
      "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
      "locationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorttyp"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
      "simNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIM-Nummer"])},
      "statusLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschriftung"])},
      "placeholder": {
        "simNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["00000000000000000000"])},
        "internetType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen Internettyp"])},
        "controllerIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0.0.0.0"])},
        "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbieter auswählen"])},
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doe"])},
        "pppoeUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PPPOE-Benutzername"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*********"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei auswählen"])},
        "statusLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lightspeed"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["info", "@", "amplr.be"])},
        "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.amplr.be"])},
        "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner auswählen"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokeren"])},
        "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9160"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieptestraat 2"])},
        "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BE0768.611.469"])},
        "downloadSpeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50"])},
        "uploadSpeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50"])},
        "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["127.0.0.1"])},
        "deviceGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lightspeed"])},
        "venueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amplr"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amplr"])},
        "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["John"])},
        "searchProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte suchen"])},
        "searchLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort suchen"])},
        "legalEntity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juristische Person auswählen"])},
        "subscriptionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die Abonnement-ID ein: sub_xxxxx"])},
        "subscriptionItemId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die Abonnement-Artikel-ID ein: si_xxxxx"])},
        "controllerAlias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein erster Cloudcontroller"])},
        "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+3247777777"])},
        "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raum"])},
        "mailchimpAudiences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie Ihr Mailchimp-Publikum"])}
      },
      "controllerIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller-IP"])},
      "amountGateways": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl Gateways"])},
      "amountAp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl AP"])},
      "amountSwitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl Switch"])},
      "pppoeUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PPPOE-Benutzername"])},
      "pppoePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PPPOE-Passwort"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
      "venueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standortname"])},
      "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
      "box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Box"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmenname"])},
      "sector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sektor auswählen"])},
      "spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Räume"])},
      "subnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Netzwerke"])},
      "legalEntity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juristische Person"])},
      "placholder": {
        "downloadSpeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20"])}
      },
      "subscriptionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripe Abonnement ID"])},
      "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindungstyp"])},
      "hasFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Failover"])},
      "subscriptionItemId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement Artikel ID"])},
      "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie den Anbieter"])},
      "tooltip": {
        "controllerUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die vollständige Controller-URL mit dem Port an, falls erforderlich. Zum Beispiel: https://yourdomain.com:8443 oder https://193.12.123.12:8443."])},
        "controllerAlias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihrem Controller einen Alias zur internen Referenz. Wenn das Feld leer bleibt, wird eine zufällige Zeichenfolge generiert."])}
      },
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
      "controllerUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller-URL"])},
      "controllerAlias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller-Alias"])},
      "replyTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Antwortadresse"])},
      "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raum/Platz"])},
      "providerSatisfaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbieterzufriedenheit"])},
      "providerSatisfactionFreeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warum ist der Kunde zufrieden"])},
      "providerIssuesReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund auswählen"])},
      "selectFollowUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow-up auswählen"])}
    },
    "fileInput": {
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei entfernen"])}
    },
    "select": {
      "selectDeviceGroupType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systemtyp"])}
    },
    "label": {
      "selectNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welchem Netzwerk gehört dieses Gerät an?"])},
      "clientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie dem Gerät einen Alias"])},
      "useStaticIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwenden Sie eine statische/feste IP"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilder"])},
      "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angemeldet bleiben"])},
      "hasFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort wird eine Failover-Verbindung haben"])},
      "connectionTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindungstyp"])},
      "uniformBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfen Sie ein spezifisches Element aus dem Abonnement"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standortname"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
      "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
      "contactPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansprechpartner"])},
      "chooseProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie den aktuellen Internetanbieter"])},
      "posSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POS-System"])},
      "canUseFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwendet Liefer-/Cloud-Dienste/..."])},
      "terrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Terrasse"])},
      "wantsOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchte ein Angebot"])},
      "venueSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Größe auswählen"])},
      "walkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies war ein Laufkundschaft"])},
      "notInterested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht interessiert"])},
      "config": {
        "failover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failover-Lösung"])},
        "router": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Router"])},
        "isPeplink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink-Router"])},
        "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch"])},
        "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Point"])},
        "amountVenues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Standorte"])},
        "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviceplan"])},
        "installCat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installationskategorie"])},
        "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internetverbindung"])},
        "extraPeplink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl spezieller Peplink-Lizenzen (brutto)"])},
        "hasSpecialPeplink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat spezielle Peplink-Lizenz"])},
        "maxOneOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximaler Betrag für einmalige Kosten"])},
        "fixedIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feste IP"])}
      },
      "variantCaas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist Connectivity-as-a-Service"])},
      "visit": {
        "interested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interessiert"])},
        "marketingOptIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zustimmung zu Mails/Marketing"])},
        "knowsAmplr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennt amplr bereits"])}
      },
      "router": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Router"])},
      "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch(es)"])},
      "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Points"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])},
      "needsFollowUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benötigt Follow-up"])},
      "contactPersonPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer des Ansprechpartners"])},
      "contactPersonEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail des Ansprechpartners"])},
      "venueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standortname"])},
      "deviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerätename"])},
      "selfOrdering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Kiosk"])},
      "takeOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Take-out/Lieferung"])},
      "satisfactionScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zufriedenheitsrate"])},
      "providerIssuesReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund der Unzufriedenheit"])},
      "followUpMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow-up-Aktion"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle des Sprechers"])},
      "nodeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knotenname"])},
      "nodeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knotenbeschreibung"])}
    },
    "placeholder": {
      "selectNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardnetzwerk"])},
      "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen Verbindungstyp"])},
      "posSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie ein POS-System"])},
      "venueSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Größe des Standorts"])},
      "config": {
        "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen Verbindungstyp"])},
        "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen SLA-Plan"])},
        "installCat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definieren Sie die Installationskategorie"])}
      },
      "sales": {
        "loadExisting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestehende Berechnung laden"])}
      },
      "venueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bistro A Laisse"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle auswählen"])}
    },
    "noResults ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ergebnisse gefunden. Versuchen Sie es erneut."])}
  },
  "general": {
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
    "connectionRestored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben wieder eine aktive Internetverbindung auf Ihrem Gerät erkannt. Sie können amplr.cloud weiter nutzen! Drücken Sie aktualisieren oder warten Sie, bis das Fenster verschwindet."])},
    "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden"])},
    "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen"])},
    "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorte"])},
    "noConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind nicht mit dem Internet verbunden. Stellen Sie sicher, dass Sie eine aktive Internetverbindung haben, um amplr.cloud zu nutzen."])},
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])}
  },
  "group": {
    "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen"])},
    "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
    "partners": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können Sie Ihre Partner hinzufügen oder entfernen"])}
    }
  },
  "groupProfile": {
    "address": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Adresse Ihrer Gruppe"])}
    },
    "profile": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Übersicht über Ihre Gruppendetails"])}
    }
  },
  "history": {
    "table": {
      "ap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Points"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "failover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failover"])},
      "gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gateway"])},
      "noFailover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Failover"])},
      "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Keine Daten gefunden.</p><p>Passen Sie Ihren Suchbegriff an, vielleicht haben Sie mehr Glück.</p>"])},
      "switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switches"])}
    }
  },
  "info": {
    "listening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuhören..."])},
    "loadingPeplinkGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte warten, während wir alle Peplink-Gruppen der ausgewählten Organisation laden."])},
    "enableTwoFactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sichern Sie Ihr Konto mit der Zwei-Faktor-Authentifizierung. Verwenden Sie die Google Authenticator-App oder Ihren bevorzugten Passwortmanager."])},
    "twoFactorActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Konto ist mit der Zwei-Faktor-Authentifizierung gesichert."])}
  },
  "location": {
    "address": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Adresse des Standorts"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])}
    },
    "deviceGroups": {
      "addDeviceGroupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Systemgruppe erstellen"])},
      "addFirstDeviceGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie Ihre erste Gruppe"])},
      "addMoreDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr Geräte hinzufügen"])},
      "connectedClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Netzwerkgeräte"])},
      "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geräte zu Ihrer Gruppe hinzufügen"])},
      "devicesError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie mindestens 1 Gerät aus, das zu Ihrer Gruppe hinzugefügt werden soll"])},
      "editModal": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können Sie den Namen und Typ Ihrer Systemgruppe ändern"])},
        "deviceBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geräte aus Ihrer Gruppe hinzufügen oder löschen"])},
        "deviceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppengeräte aktualisieren"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppenname"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systemgruppen-Info"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppentyp"])}
      },
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihrer Gruppe einen Namen"])},
      "newDeviceGroup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cool! Wir haben eine neue Systemgruppe (", _interpolate(_named("name")), ") erstellt, damit Sie überprüfen können, ob die Geräte in der Systemgruppe online sind."])},
      "newDeviceGroupList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systemgruppengeräte"])},
      "notification": {
        "deviceGroupCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Systemgruppe wurde erstellt"])},
        "deviceGroupUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systemgruppe wurde aktualisiert"])}
      },
      "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppeninfo hinzufügen"])},
      "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geräte hinzufügen"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen Typ für Ihre Gruppe"])},
      "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie diese Systemgruppe löschen möchten?"])}
    },
    "installation": {
      "bridge": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist eine Brücke vorhanden?"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treffen Sie eine Auswahl"])}
      },
      "failover": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist ein Failover vorhanden?"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treffen Sie eine Auswahl"])}
      },
      "guest_wifi": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist das Gast-WLAN in Ordnung?"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treffen Sie eine Auswahl"])}
      },
      "images": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie einige Bilder der Installation hoch"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilder hochladen"])}
      },
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])}
    },
    "plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorte"])},
    "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])},
    "updateWlan": {
      "form": {
        "fields": {
          "verifyBeforeSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen Sie, um zu speichern."])}
        }
      },
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie die Einstellungen dieses WLAN-Netzwerks ändern, verlieren alle bereits verbundenen Geräte ihre Verbindung und müssen mit den neuen Einstellungen erneut verbunden werden."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WLAN-Einstellungen ändern für: "])},
      "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Einstellungen wirklich ändern? Bestätigen Sie, indem Sie den Namen des WLAN-Netzwerks eingeben."])}
    },
    "connection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die Verbindung für den Standort"])}
    },
    "create": {
      "header": {
        "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standortname"])},
        "connections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internetverbindungen"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnung"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorttyp"])}
      },
      "customerAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenadresse als Standortadresse verwenden"])}
    },
    "createSubscription": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement erstellen"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie ein neues Stripe-Abonnement für diesen Standort. Sie werden zu Stripe weitergeleitet."])}
    },
    "createInvoice": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung erstellen"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie eine Rechnung für einmalige Kosten oder Eingriffe."])}
    },
    "billing": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnementdetails"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier finden Sie weitere Details zum Standortabonnement."])},
      "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistungen"])},
      "serviceBilledButNotActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienst wird abgerechnet, ist aber nicht am Standort aktiv"])},
      "serviceNotBilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienst ist am Standort aktiv, wird aber nicht abgerechnet."])}
    },
    "plan": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviceplan"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legen Sie den Serviceplan für diesen Standort fest."])}
    },
    "config": {
      "unifiOs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist ein Ubiquiti OS Standort"])}
    },
    "topology": {
      "vpn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fusionhub"])},
      "tooltip": {
        "vpn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fusionhub-Verbindungen, die zwischen diesem Standort und dem Hub verfügbar sind."])}
      }
    }
  },
  "locationProfile": {
    "commands": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spezielle Befehle, die auf Ihrem Netzwerk ausgeführt werden sollen"])},
      "reboot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Netzwerkgeräte neu starten"])},
      "rebootNetwork": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie alle Netzwerkgeräte neu starten, ist das Internet an diesem Standort vorübergehend nicht verfügbar. Das Neustarten dieser Geräte kann mehrere Minuten dauern. Sind Sie sicher?"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Netzwerkgeräte neu starten"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommandozentrale"])}
    },
    "hours": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie hier Ihre Öffnungszeiten ein"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnungszeiten"])}
    },
    "info": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Details zu Ihrem Standort"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])}
    },
    "qr": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffentlichen QR-Code generieren"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein öffentlicher QR-Code ermöglicht es Ihren Mitarbeitern, schnell den Status des Netzwerks abzurufen. Integrieren Sie den QR-Code in Ihre Verfahren oder platzieren Sie ihn vor Ort."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support-QR"])}
    },
    "service": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basierend auf Ihrem Serviceplan können Sie folgende Dienste nutzen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbare Dienste"])}
    }
  },
  "locations": {
    "payment": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Übersicht über Ihre Zahlungsmethoden, aktuellen Abonnements und Rechnungen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwalten Sie Ihre Zahlungen"])}
    },
    "table": {
      "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Keine Standorte gefunden.</p><p>Versuchen Sie Ihr Glück und passen Sie den Suchbegriff an.</p>"])},
      "qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-Code herunterladen"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen"])},
      "siteName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])},
      "sitePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
    },
    "installed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
    "notInstalled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht installiert"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])}
  },
  "mainNav": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "admin": {
      "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguration"])},
      "controllers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller"])},
      "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungen"])},
      "inventory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventar"])},
      "zerotier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zerotier"])}
    },
    "app": {
      "b2c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht"])},
      "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen"])},
      "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal"])}
    },
    "button": {
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])}
    },
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
    "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])},
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnements"])},
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrieb"])},
    "refferal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfehlungsbonus"])},
    "referral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfehlungsbonus"])}
  },
  "modalAdminAddCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden hinzufügen"])},
  "mydata": {
    "download": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie Ihre Daten herunterladen möchten, geben Sie bitte unten Ihre E-Mail-Adresse ein."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten herunterladen"])}
    },
    "optout": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie möchten, dass wir Ihre Daten löschen, geben Sie bitte unten Ihre E-Mail-Adresse ein."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten löschen"])}
    }
  },
  "nav": {
    "account": {
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])}
    },
    "admin": {
      "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden"])},
      "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguration"])},
      "controllers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller"])},
      "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
      "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorte"])},
      "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
      "sales": {
        "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht"])},
        "createVisit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besuch erstellen"])},
        "configCalculator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurationsrechner"])}
      },
      "inventory": {
        "inventory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventar"])},
        "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMPLR-Produkte"])},
        "logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protokolle"])},
        "allocatedCaas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugeordneter CaaS"])}
      }
    },
    "button": {
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])}
    },
    "customer": {
      "b2b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B2B"])},
      "b2c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B2C"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
      "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungen"])},
      "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorte"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen"])},
      "titleB2c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statusseite"])},
      "branding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branding"])}
    },
    "dashboard": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
    },
    "deviceGroups": {
      "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geräte"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])}
    },
    "group": {
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])}
    },
    "locations": {
      "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geräte"])},
      "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
      "deviceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systeme"])},
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlauf"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
      "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gäste"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorte"])},
      "topology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup"])},
      "vendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbieter"])},
      "installationReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installationsbericht"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statusseite"])},
      "tenantsManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mieter"])},
      "configReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurationsbericht"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentare"])},
      "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnung"])}
    },
    "portal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal"])}
    },
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
    "users": {
      "invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einladungen"])},
      "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])}
    },
    "partner": {
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])}
    },
    "invites": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einladungen"])}
    },
    "access": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriff"])}
    }
  },
  "noAccess": {
    "body": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider haben Sie keinen Zugriff auf diese Seite."])},
      "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider haben Sie keinen Zugriff auf diese Seite. Aktualisieren Sie Ihren Plan, um diese Seite zu sehen."])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Zugriff."])},
    "titleNoPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Zugriff. Bitte geben Sie Ihr Passwort ein."])}
  },
  "noPlan": {
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen einen höheren Plan haben, um auf diese Seite zuzugreifen. Kontaktieren Sie uns für die Möglichkeiten."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren Sie Ihren Plan"])}
  },
  "notPayed": {
    "body": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Konto ist vorübergehend deaktiviert. Bitte wenden Sie sich an den Administrator Ihres Unternehmens, um die Zahlungsinformationen zu vervollständigen."])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Zugriff"])}
  },
  "notification": {
    "customer": {
      "noPaymentMethod": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Es ist derzeit keine Zahlungsmethode für ", _interpolate(_named("customerName")), " festgelegt. Vermeiden Sie die automatische Trennung Ihres Internets und Ihrer Dienste."])},
      "paymentMethodSet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben eine aktive Zahlungsmethode. Wenn Sie diese ändern möchten, gehen Sie zur Zahlungsseite."])}
    },
    "demoMode": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hallo ", _interpolate(_named("name")), ", Sie sehen amplr.cloud im Demo-Modus. Keine Sorge, keine Funktionen sind versteckt! Wenn Sie Fragen zur Plattform haben, wenden Sie sich bitte an support", "@", "amplr.be!"])},
    "snooze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snooze-Einstellung aktualisiert"])},
    "imposter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie sind derzeit als <strong>", _interpolate(_named("name")), "</strong> angemeldet. Wenn Sie den Impostermodus beenden möchten, drücken Sie die Stopp-Taste!"])},
    "referral": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danke für die Empfehlung! Wir werden uns mit Ihrem Lead in Verbindung setzen und Sie auf dem Laufenden halten. Sie können eine weitere Empfehlung hinzufügen, wenn Sie möchten!"])}
    }
  },
  "openingHours": {
    "createBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie Öffnungszeiten für diesen Standort. Sie können diese verwenden, um Ihr Gästeportal offline zu schalten, wenn das Geschäft geschlossen ist."])},
    "error": {
      "timePerDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie eine Öffnungs- und Schließzeit für jeden offenen Tag ein."])},
      "times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie eine Öffnungs- und Schließzeit ein."])}
    },
    "everyDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeden Tag gleiche Öffnungs- und Schließzeit"])},
    "fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fr"])},
    "mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mo"])},
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sind noch keine Öffnungszeiten festgelegt"])},
    "sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sa"])},
    "sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So"])},
    "thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
    "tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di"])},
    "wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi"])}
  },
  "pagination": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])}
  },
  "permissions": {
    "customer": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen"])}
    },
    "customer_read": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibt dem Benutzer die Berechtigung, Unternehmensdaten anzuzeigen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen anzeigen"])}
    },
    "customer_update": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibt dem Benutzer die Berechtigung, Unternehmensinformationen zu ändern."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen ändern"])}
    },
    "group": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])}
    },
    "group_read": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibt dem Benutzer die Berechtigung, Gruppendaten anzuzeigen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe anzeigen"])}
    },
    "group_update": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibt dem Benutzer die Berechtigung, Gruppendaten zu bearbeiten."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe bearbeiten"])}
    },
    "invoices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungen"])}
    },
    "invoices_read": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibt dem Benutzer die Berechtigung, Rechnungsinformationen anzuzeigen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungen anzeigen"])}
    },
    "invoices_update": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibt dem Benutzer die Berechtigung, Rechnungsinformationen zu bearbeiten."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungen ändern"])}
    },
    "location": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])}
    },
    "location_read": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibt dem Benutzer die Berechtigung, Standortdaten anzuzeigen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort anzeigen"])}
    },
    "location_update": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibt dem Benutzer die Berechtigung, Standortinformationen zu bearbeiten."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort ändern"])}
    },
    "portal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal"])}
    },
    "portal_read": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibt dem Benutzer die Berechtigung, Portaldaten anzuzeigen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal anzeigen"])}
    },
    "portal_update": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibt dem Benutzer die Berechtigung, Portaldaten zu bearbeiten."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal ändern"])}
    },
    "users": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])}
    },
    "users_create": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibt dem Benutzer die Berechtigung, Benutzer zu bestimmten Gruppen, Unternehmen oder Standorten hinzuzufügen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer hinzufügen"])}
    },
    "users_delete": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibt dem Benutzer die Berechtigung, Benutzer zu löschen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer löschen"])}
    },
    "users_read": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibt dem Benutzer die Berechtigung, Benutzerdaten anzuzeigen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer anzeigen"])}
    },
    "users_update": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibt dem Benutzer die Berechtigung, Benutzerdaten zu bearbeiten."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer ändern"])}
    },
    "noAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keine Berechtigung, Aktionen auszuführen."])}
  },
  "policy": {
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Durch die Verbindung mit diesem Gastnetzwerk erkennen Sie an, dass Sie diese Vereinbarung gelesen, verstanden und akzeptiert haben. Dieses Gastnetzwerk wird von den Eigentümern bereitgestellt und liegt in deren alleinigem Ermessen. Ihr Zugang zum Netzwerk kann jederzeit aus beliebigem Grund blockiert, ausgesetzt oder beendet werden. Sie stimmen zu, das drahtlose Netzwerk nicht für ungesetzliche Zwecke zu nutzen und übernehmen die volle Verantwortung für Ihre Handlungen. Das Drahtlosnetzwerk wird 'wie es ist' ohne jegliche ausdrücklichen oder stillschweigenden Garantien bereitgestellt.</p><p>Um die Verbindung zu diesem Gastnetzwerk herzustellen, wird die MAC-Adresse des zu verbindenden Clients verwendet und gespeichert.</p><p>AMPLR BV kann in keiner Weise für die Nutzung dieses drahtlosen Netzwerks haftbar gemacht werden.</p><p>AMPLR BV kann Daten bei der Anmeldung im Netzwerk speichern, falls dies angefordert wird. Unter keinen Umständen werden diese Daten zu Marketingzwecken verwendet, es sei denn, der Benutzer gibt seine Zustimmung (Opt-in).</p><p>Wenn der Benutzer seine Daten einsehen, ändern oder löschen möchte, kann er uns unter dataprotection[at]amplr.be kontaktieren.</p>"])},
    "optout": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie Ihre Daten aus unserer Datenbank einsehen oder löschen möchten, klicken Sie auf die Schaltfläche unten."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Daten"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten einsehen und löschen"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Geschäftsbedingungen"])},
    "titleOffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Büro"])}
  },
  "portal": {
    "body": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffentliches WLAN ist derzeit nicht verfügbar. Bitte versuchen Sie es später erneut."])},
      "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben derzeit geschlossen."])},
      "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Klicken auf Verbinden stimmen Sie unseren"])},
      "policyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeinen Geschäftsbedingungen"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<a href=\"https://www.amplr.be\" target=\"_blank\" class=\"has-text-primary has-text-weight-bold\">amplr</a> bietet kostenloses, sicheres und schnelles WLAN an diesem Standort. Drücken Sie auf Verbinden und beginnen Sie zu surfen."])},
      "trusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1000+ Standorte nutzen <a href=\"https://www.amplr.be\" target=\"_blank\" class=\"has-text-primary has-text-weight-bold\">amplr</a>"])},
      "connecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Verbindung wird gesichert"])}
    },
    "client": {
      "email": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihre E-Mail-Adresse ein"])}
      },
      "emailOptIn": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Daten dürfen für Marketingzwecke verwendet werden."])}
      },
      "firstname": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihren Vornamen ein"])}
      },
      "lastname": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihren Nachnamen ein"])}
      },
      "phonenumber": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihre Telefonnummer ein"])}
      },
      "premiumWifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Das Opt-in führt zu schnellerem WLAN)"])},
      "forceOptIn": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um das kostenlose WLAN nutzen zu können, erkläre ich mich damit einverstanden, dass meine Daten für Marketingzwecke verwendet werden."])}
      },
      "emailOptInPiggy": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Daten können zu Marketingzwecken und zur Kundenbindung genutzt werden."])}
      }
    },
    "overview": {
      "allTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immer verbundene Gäste"])},
      "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle verbundenen Gäste heute"])},
      "todayOptIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing-Opt-in noch heute"])}
    },
    "settings": {
      "advancedSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterte Einstellungen"])},
      "analytics": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie eine Google Tag Manager oder Facebook Pixel ID hinzu, um Tracking zu Ihrem Portal hinzuzufügen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytics"])}
      },
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung, Sie bearbeiten die allgemeinen Kundeneinstellungen"])},
      "data": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurieren Sie hier, welche Daten Sie vom Benutzer erfassen möchten."])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten anzeigen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten erfassen"])}
      },
      "design": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren Sie Bilder, Farben und Texte Ihres Portals."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal konfigurieren"])}
      },
      "form": {
        "analyticsFacebook": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook Pixel ID"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123456789123456"])}
        },
        "analyticsGoogle": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Tag Manager ID"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GTM-ABC1234"])}
        },
        "analyticsPrivacyPolicy": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL der Datenschutzerklärung"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.amplr.be/privacy-policy"])}
        },
        "background": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hintergrundbild (jpg, png)"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie eine Datei"])}
        },
        "bgColor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hintergrundfarbe"])}
        },
        "body": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körpertext anpassen"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Text für den Körper"])}
        },
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite konfigurieren"])},
        "buttonText": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schaltflächentext anpassen"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Text für die Schaltfläche"])}
        },
        "client": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn deaktiviert, kann sich der Benutzer ohne Eingabe von Daten verbinden."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche Daten möchten Sie vom Benutzer erfassen?"])}
        },
        "color": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie eine Farbe"])}
        },
        "customer": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundeneinstellungen verwenden"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können den Unternehmenseinstellungen folgen, sodass Sie kein Portal für jeden Standort einrichten müssen."])}
        },
        "disabled": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal vorübergehend offline stellen"])}
        },
        "disabledText": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text für vorübergehend offline anpassen"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Text für den Fall, dass das Portal vorübergehend offline ist"])}
        },
        "duration": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauer (min)"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z.B. 30"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definieren Sie, wie lange der Gast nach der Authentifizierung verbunden bleiben kann."])}
        },
        "logo": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo (svg, jpg, png)"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie eine Datei"])}
        },
        "mainColor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptfarbe"])}
        },
        "offlineText": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text für offline anpassen"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Text für den Fall, dass das Portal offline ist"])}
        },
        "password": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie ein Passwort ein"])}
        },
        "passwordToggle": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zur Anmeldung verwenden"])}
        },
        "schedule": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnungszeiten ändern"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal außerhalb der Öffnungszeiten offline stellen"])}
        },
        "secondaryColor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzentfarbe"])}
        },
        "secondaryTextColor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzenttextfarbe"])}
        },
        "textColor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Textfarbe"])}
        },
        "toggle": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalisierte Portal"])}
        },
        "url": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landeseite"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z.B. https://www.amplr.be"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definieren Sie, wohin Gäste nach der Authentifizierung weitergeleitet werden sollen. Dies kann Ihre Website oder eine andere Landingpage sein."])}
        },
        "webhookEndpoint": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook-URL"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://yourcustomwebhook.zapier.com"])}
        },
        "webhookSecret": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook Shared Secret"])}
        },
        "primaryColor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primärfarbe"])}
        },
        "forceOptIn": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt-in für Marketing erzwingen"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch die Aktivierung dieser Funktion müssen Kunden, die eine Verbindung herstellen möchten, das Kontrollkästchen für Marketingzwecke aktivieren."])}
        },
        "forceEmailValidation": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch die Verwendung der erzwungenen E-Mail-Validierung werden alle E-Mail-Adressen von einem Drittanbieter validiert, um zu prüfen, ob die verwendete Adresse legitim ist."])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren Sie die E-Mail-Adressvalidierung"])}
        },
        "strictEmailValidation": {
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine strenge Validierung bedeutet, dass nur Adressen zugelassen werden, die einen Gesundheitswert von 100 haben. \nWenn die Punktzahl niedriger, aber immer noch lieferbar ist, erhält der Benutzer eine Fehlermeldung, da die strikte Validierung aktiviert ist."])}
        },
        "strictValidation": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strenge Validierung erzwingen"])}
        }
      },
      "general": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren Sie allgemeine Einstellungen Ihres Portals."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])}
      },
      "login": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie hier, wie ein Benutzer sich in Ihr öffentliches WLAN einloggen soll. Wenn nichts ausgewählt ist, kann sich der Benutzer ohne Authentifizierung verbinden."])},
        "facebook": {
          "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihren Facebook-API-Schlüssel ein"])},
          "inputSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihren Facebook-API-Geheimschlüssel ein"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook API-Schlüssel"])},
          "placeholderSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook API-Geheimschlüssel"])},
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook-Login-Schaltfläche anzeigen"])}
        },
        "google": {
          "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihren Google-API-Schlüssel ein"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google API-Schlüssel"])},
          "small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis: Google-Login funktioniert nicht auf iOS-Captive-Portalen"])},
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google-Login-Schaltfläche anzeigen"])}
        },
        "linkedin": {
          "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihren LinkedIn-API-Schlüssel ein"])},
          "inputSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihren LinkedIn-API-Geheimschlüssel ein"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn API-Schlüssel"])},
          "placeholderSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn API-Geheimschlüssel"])},
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn-Login-Schaltfläche anzeigen"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierung"])}
      },
      "preview": {
        "desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desktop"])},
        "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobil"])},
        "tablet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablet"])}
      },
      "schedule": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal vorübergehend offline stellen oder außerhalb der Öffnungszeiten offline stellen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])}
      },
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Änderungen wurden gespeichert."])},
      "tabs": {
        "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])},
        "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Französisch"])},
        "layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layout"])},
        "nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederländisch"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
        "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])}
      },
      "webhook": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwenden Sie Webhooks, um externe Anwendungen zu triggern."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhooks"])}
      },
      "customPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derzeit wird eine von amplr gebrandete Seite als Gast-WLAN-Portal verwendet. Aktivieren Sie das benutzerdefinierte Portal, um Ihr Branding zu verwenden."])},
      "integrations": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrationen"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzen Sie Ihr Gast-WLAN optimal, indem Sie unsere Integrationen verwenden."])}
      }
    },
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Einstellungen wurden gespeichert."])},
    "table": {
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
      "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer blockieren"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
      "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerät"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
      "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-Adresse"])},
      "mac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAC-Adresse"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Keine Benutzer gefunden.</p><p>Versuchen Sie es erneut und passen Sie die Filter an.</p>"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche nach E-Mail oder MAC-Adresse"])},
      "unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer entsperren"])}
    },
    "integration": {
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
      "message": {
        "piggy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treten Sie dem <b>Treueprogramm</b> bei und erhalten Sie sofort Vergünstigungen."])}
      }
    },
    "integrations": {
      "smartendrModal": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smartendr"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um diese Integration zu nutzen, müssen Sie ein aktives Smartendr-Abonnement haben."])},
        "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Integration aktivieren"])},
        "openDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smartendr öffnen"])},
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Integration aktivieren"])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-Schlüssel in Ihrem Smartendr-Konto einfügen"])},
        "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese eindeutige ID im Smartendr-Dashboard einfügen, um den Standort zu identifizieren"])}
      },
      "apiKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser API-Schlüssel wird nur einmal angezeigt"])},
      "locationId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Standort-ID ist für jeden Standort einzigartig"])},
      "piggyModal": {
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihren API-Schlüssel ein, den Sie im Piggy-Dashboard finden"])},
        "openDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piggy öffnen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piggy"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um diese Integration zu nutzen, benötigen Sie ein aktives Abonnement bei Piggy."])}
      },
      "mailchimpModal": {
        "openDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailchimp öffnen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailchimp"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um diese Integration zu nutzen, benötigen Sie ein aktives Mailchimp-Abonnement."])},
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Integration aktivieren"])},
        "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Integration aktivieren"])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie Ihren API-Schlüssel ein, den Sie im Mailchimp-Dashboard finden"])},
        "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie Ihr Publikum aus"])}
      }
    },
    "error": {
      "mailNotvalidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoppla! \nEs sieht so aus, als ob diese E-Mail-Adresse ein Versteckspiel spielt. \nVersuchen Sie es bitte noch einmal mit einem echten!"])}
    }
  },
  "scheduler": {
    "buttons": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum hinzufügen"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])}
    },
    "duration": {
      "always": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immer"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit(en) insgesamt"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauer"])},
      "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis"])}
    },
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von"])},
    "repeat": {
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täglich"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlich"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht wiederholen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederholen"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wöchentlich"])},
      "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jährlich"])}
    },
    "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis"])}
  },
  "service": {
    "customGuestPortal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebrandetes Gast-WLAN"])},
    "emailSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Support"])},
    "nextDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste Tag Intervention"])},
    "phoneSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon-Support"])}
  },
  "status": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
    "notification": {
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind hier, um zu helfen. Sie können uns telefonisch kontaktieren"])}
    },
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
    "standby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereit"])},
    "toDeploy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht installiert"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade Standortdetails..."])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist der Status Ihrer Internet- und WLAN-Lösung an:"])},
    "issues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie ein Problem mit etwas?"])},
    "problems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anderes Problem?"])},
    "urgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dringende Unterstützung benötigt?"])},
    "buttons": {
      "amplr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktieren Sie amplr"])}
    },
    "settings": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurieren Sie Ihre Statusseite"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Statusseite ist die Seite, die Ihre Mitarbeiter sehen, wenn sie den amplr-QR-Code scannen. Sie können alle verschiedenen Supportinformationen auf dieser Seite hinzufügen."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite konfigurieren"])},
      "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehen Sie zum Designer-Tool für Ihre Statusseite, indem Sie auf die Schaltfläche unten klicken"])},
      "noButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben noch keine Kontakt-Schaltflächen konfiguriert"])},
      "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt-Schaltfläche hinzufügen"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschriftung"])},
      "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen Ihre Änderungen veröffentlichen, damit die Änderungen live übernommen werden."])}
    },
    "gettingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade Details..."])},
    "notInstalled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht installiert"])},
    "notConfigured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht konfiguriert"])},
    "reportMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurationsbericht"])},
    "liveLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installiert"])},
    "archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiviert"])},
    "deviceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre aktiven Systeme"])},
    "deviceGroupOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Geräte in dieser Systemgruppe sind online!"])},
    "deviceGroupWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige Geräte in dieser Systemgruppe sind derzeit nicht verbunden. Bitte überprüfen Sie das!"])},
    "deviceGroupOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keines der Geräte in der Systemgruppe scheint online zu sein. Dies hat erhebliche Auswirkungen. Bitte überprüfen Sie die Gruppe."])},
    "deviceGroupList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geräteübersicht"])},
    "deviceOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerät offline"])},
    "deviceGroupOfflineInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Gerät ist entweder nicht an eine Stromquelle angeschlossen oder nicht über ein Kabel oder WLAN mit dem Internet verbunden. Bitte überprüfen Sie die Verbindungen. Identifizieren Sie das Gerät anhand der oben angegebenen MAC-Adresse."])}
  },
  "success": {
    "admin": {
      "config": {
        "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Konfiguration wurde hinzugefügt."])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Konfiguration wurde gelöscht."])}
      },
      "customer": {
        "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kunde wurde hinzugefügt."])},
        "addedCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kunde wurde zur Gruppe hinzugefügt."])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kunde wurde gelöscht."])},
        "edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kunde wurde bearbeitet."])},
        "locationCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Standort wurde dem Kunden hinzugefügt."])},
        "userAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Benutzer wurde dem Kunden hinzugefügt."])}
      },
      "group": {
        "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Gruppe wurde hinzugefügt."])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Gruppe wurde aktualisiert."])}
      },
      "location": {
        "configCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Konfiguration wurde gespeichert."])},
        "configUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Konfiguration wurde aktualisiert."])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Standort wurde gelöscht."])},
        "infoUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Änderungen wurden gespeichert."])},
        "installFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Änderungen wurden gespeichert."])},
        "updatedVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Änderungen wurden gespeichert."])},
        "configReportCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Bericht wurde gespeichert."])},
        "commentAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Kommentar wurde erfolgreich hinzugefügt."])},
        "commentUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Bericht wurde aktualisiert."])},
        "commentDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Bericht wurde gelöscht."])},
        "controllerLinksCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Controller wurden konfiguriert."])}
      },
      "userDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Benutzer wurde gelöscht."])},
      "userLinkDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Benutzer wurde gelöscht."])},
      "partner": {
        "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Partner wurde hinzugefügt."])},
        "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Partner wurde gelöscht."])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Partner wurde aktualisiert."])},
        "usersAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Benutzer wurden eingeladen."])},
        "linkDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Einladung wurde gelöscht."])},
        "userDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Benutzer wurde gelöscht."])},
        "accessAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Zugriff wurde hinzugefügt."])},
        "locationDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Standort wurde gelöscht."])},
        "customerDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kunde wurde gelöscht."])},
        "groupDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Gruppe wurde gelöscht."])}
      }
    },
    "auth": {
      "passwordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort erfolgreich geändert. Sie können sich jetzt anmelden."])},
      "passwordResetLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen Sie Ihr Postfach auf den Rücksetzlink."])}
    },
    "customer": {
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info erfolgreich aktualisiert."])},
      "logoSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Logo wurde gespeichert."])}
    },
    "portalClient": {
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Daten wurden gelöscht."])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Daten werden in wenigen Momenten heruntergeladen."])},
      "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben Ihnen eine E-Mail gesendet. Überprüfen Sie Ihr Postfach."])}
    },
    "user": {
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In die Zwischenablage kopiert."])},
      "passwordChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort erfolgreich aktualisiert."])},
      "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Änderungen wurden erfolgreich aktualisiert."])}
    },
    "users": {
      "userEdited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Änderungen wurden gespeichert."])},
      "invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Einladung wurde gesendet."])}
    },
    "clientSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Einstellungen des Clients wurden erfolgreich aktualisiert."])},
    "clientDhcpRemoved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben die feste IP von diesem Client entfernt."])},
    "savedDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Einstellungen wurden gespeichert."])},
    "statusButtonSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Einstellungen wurden gespeichert."])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich kopiert!"])},
    "partner": {
      "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Partner wurde hinzugefügt."])},
      "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Partner wurde entfernt."])}
    },
    "referral": {
      "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Empfehlung wurde gesendet."])}
    },
    "poeCycled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PoE-Stromkreislauf wiederhergestellt!"])},
    "portChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portänderungen angewendet!"])},
    "branding": {
      "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen gespeichert."])},
      "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branding erstellt."])}
    },
    "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen gespeichert."])}
  },
  "support": {
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hmmm... Wir bemerken einige Probleme. Kontaktieren Sie uns!"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rufen Sie uns an"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+32 3 375 60 70"])}
  },
  "tooltip": {
    "portal": {
      "webhooks": {
        "endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die URL, die die Anfrage erhält. Tools wie zapier.com oder typedhook.com bieten Webhook-Dienste an, um sich mit Ihrem CRM oder anderen Automatisierungstools zu integrieren."])},
        "secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Geheimnis wird im Anforderungsheader ('amplr-wh-secret') gesendet, verwenden Sie es, um zu überprüfen, ob die eingehende Anfrage legitim ist."])}
      }
    },
    "portalTab": {
      "allToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle verbundenen Kunden für heute, auch diejenigen, die sich nicht für das Marketing angemeldet haben."])},
      "allTimeOptIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Kunden, die sich von Anfang an für Marketing entschieden haben."])},
      "allTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ständig verbundene Kunden, auch diejenigen, die sich nicht für Marketing entschieden haben."])}
    }
  },
  "userProfile": {
    "address": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Profiladresse"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])}
    },
    "profile": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Überblick über Ihre Profildaten"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])}
    },
    "security": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können Sie sensible Daten bearbeiten"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheit"])}
    },
    "setupTwoFactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einrichtung der Zwei-Faktor-Authentifizierung"])},
    "twoFactorScan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scannen Sie den QR-Code mit Ihrer Authentifikator-App (Google Authenticator, 1Password, Bitwarden...). Können Sie den Code nicht scannen? Kopieren und fügen Sie die URL ein!"])},
    "2fa": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwei-Faktor-Authentifizierung"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sichern Sie Ihr Konto mit der Zwei-Faktor-Authentifizierung"])},
      "finishButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einrichtung abschließen"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwei-Faktor-Authentifizierung erfolgreich konfiguriert."])},
      "codeValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um die 2FA-Einrichtung zu bestätigen, bestätigen Sie bitte mit Ihrem neu generierten 2FA-Code."])}
    }
  },
  "users": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer hinzufügen"])},
    "addAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriff hinzufügen"])},
    "customer": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Unternehmen"])},
      "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen Kunden"])}
    },
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigungen bearbeiten"])},
    "group": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Gruppen"])},
      "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie eine Gruppe"])},
      "noGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt keine Gruppen zur Auswahl"])}
    },
    "location": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Standorte"])},
      "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen Standort"])}
    },
    "steps": {
      "access": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie, auf welche Gruppen, Unternehmen oder Standorte der Benutzer zugreifen soll."])},
        "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriff gewähren"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerzugriff gewähren"])}
      },
      "button": {
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächster Schritt"])},
        "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorheriger Schritt"])}
      },
      "email": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie eine oder mehrere E-Mail-Adressen der Personen hinzu, die Sie hinzufügen möchten."])},
        "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail hinzufügen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adressen hinzufügen"])}
      },
      "invite": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen Sie die Details, wenn alles korrekt ist, können Sie die Einladungen durch Klicken auf die Schaltfläche senden."])},
        "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absenden"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einladung senden"])}
      },
      "permissions": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie aus, welche Berechtigungen Sie dem Benutzer geben möchten."])},
        "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechte vergeben"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechte vergeben an"])}
      }
    },
    "table": {
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link kopieren"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einladungslink"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])}
    },
    "title": {
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorte"])}
    },
    "type": {
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])}
    },
    "notification": {
      "lastPermission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Entfernen der letzten Berechtigung in dieser Tabelle führt zu einem deaktivierten Konto ohne jeglichen Zugriff. Bitte seien Sie vorsichtig, wenn Sie diese letzte Berechtigung entfernen!"])},
      "noAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist kein Zugriff für diesen Benutzer definiert, bitte fügen Sie Zugriff hinzu oder löschen Sie das Benutzerkonto."])}
    }
  },
  "validations": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist keine gültige E-Mail"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort sollte mindestens 8 Zeichen enthalten. Es sollte auch mindestens einen Großbuchstaben, einen Kleinbuchstaben, ein Sonderzeichen enthalten."])},
    "phoneValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist keine gültige Telefonnummer"])},
    "postalCodeValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist keine gültige Postleitzahl"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld ist erforderlich"])},
    "sameAsPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Passwörter stimmen nicht überein"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist keine gültige URL."])},
    "validVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist keine gültige USt-IdNr."])},
    "between": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Zahl sollte zwischen ", _interpolate(_named("min")), " und ", _interpolate(_named("max")), " liegen."])},
    "ipExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese IP-Adresse ist bereits vergeben. Bitte wählen Sie eine andere Adresse."])},
    "decimalDot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist kein gültiges Format. Verwenden Sie einen Punkt (.)."])}
  },
  "webhook": {
    "log": {
      "selectCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen Anruf links aus, um die Details zu sehen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webhook-Protokoll"])}
    }
  },
  "user": {
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer einladen"])}
  },
  "tenants": {
    "table": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
      "deactivateOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktivieren am"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
      "space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raum/Räume"])}
    },
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
    "addTenants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mieter hinzufügen"])},
    "creating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moment mal! Wir erstellen die Mieter..."])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier."])},
    "uploadCsv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer aus Datei importieren"])},
    "addManual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mieter manuell hinzufügen"])},
    "importComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Import wurde erfolgreich abgeschlossen."])},
    "amountImported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Mieter erstellt"])},
    "errorsOccured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Erstellen"])},
    "deactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktiviert"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "editTenant": {
      "chooseDeactivationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie ein Datum für die zukünftige Deaktivierung"])},
      "offboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mieter durch Auswahl eines Datums für die Deaktivierung abmelden oder die Schaltfläche Jetzt deaktivieren drücken, um den Zugriff eines Mieters sofort zu entfernen."])},
      "deactivateNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt deaktivieren?"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mieter bearbeiten"])},
      "scheduleNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben die Deaktivierung dieses Mieters bereits geplant. Haben Sie dies versehentlich getan oder möchten Sie es entfernen?"])},
      "undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geplantes Datum entfernen"])},
      "saveAndSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern und E-Mail senden"])}
    },
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Mieter wurden erstellt"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "selectSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raum auswählen"])},
    "edit": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen erfolgreich gespeichert."])}
    }
  },
  "tenant": {
    "csvInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importieren Sie eine CSV-Datei mit einer Kopfzeile, die zwei Spalten enthält: Name, E-Mail. In der zweiten Zeile fügen Sie den Namen in die erste Spalte und die E-Mail in die zweite Spalte ein. Sind Sie sich nicht sicher, ob Sie dies richtig machen? Laden Sie Ihr Beispiel-CSV herunter"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scannen Sie den QR-Code oder verwenden Sie die folgenden Details, um eine Verbindung herzustellen"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WLAN"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passphrase"])},
    "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hallo ", _interpolate(_named("name")), ", hier finden Sie Ihre Anmeldeinformationen für das WLAN bei"])},
    "delete": {
      "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie diesen Mieter löschen möchten? Das Löschen eines Mieters entfernt alle Einstellungen bezüglich des Mieters."])}
    }
  },
  "locationTypes": {
    "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haus"])},
    "horeca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horeca"])},
    "retail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzelhandel"])},
    "cohousing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohousing"])},
    "coworking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coworking"])},
    "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
    "office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Büro"])},
    "sports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sporteinrichtung"])}
  },
  "financials": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzziel 2023"])}
  },
  "passwordConfirmModal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen Sie Ihr Passwort"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen Ihr Passwort bestätigen, bevor Sie fortfahren können."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort bestätigen"])}
  },
  "ppskNotConfigured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Mieter-Management ist nicht aktiviert. Bitte wenden Sie sich an den Amplr-Support, um Ihnen bei der Einrichtung dieser Funktion zu helfen."])},
  "global": {
    "fiber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glasfaser"])},
    "vdsl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VDSL"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine"])}
  },
  "payments": {
    "methods": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsmethoden"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie einen Link, um eine SEPA-/Kreditkartenzahlungsmethode einzurichten, damit wir Kunden wiederkehrend belasten können."])}
    },
    "caasPrice": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CaaS-Preis erstellen"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie eine neue Preis-ID in Stripe für einen Standort mit Connectivity-as-a-Service."])}
    },
    "stepList": {
      "openStripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnen Sie diesen Stripe-Link, um ein Abonnement zu erstellen"])},
      "pasteProductId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie diese Preis-ID in das Produktsuchfeld ein"])},
      "metaData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie Metadaten hinzu, verwenden Sie 'siteId' als Schlüssel und fügen Sie die folgende Standort-ID in den Wert ein"])},
      "invoiceMemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie den folgenden Wert in das Rechnungsmemofeld ein (unter erweitert)"])},
      "createSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drücken Sie die Schaltfläche 'Abonnement erstellen', um abzuschließen."])}
    }
  },
  "modal": {
    "confirm": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher?"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen Sie die Löschung, indem Sie eingeben:"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben den falschen Wert eingegeben"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte bestätigen Sie Ihre Aktion."])}
    },
    "save": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unter welchem Namen möchten Sie dies speichern?"])}
    }
  },
  "partner": {
    "profile": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info über den Partner"])}
    },
    "address": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Adresse des Partners"])}
    },
    "access": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriff hinzufügen"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie eine oder mehrere Gruppen, Kunden oder Standorte, auf die der Partner zugreifen darf."])}
    }
  },
  "partnerProfile": {
    "profile": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Überblick über Ihre Details"])}
    },
    "address": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Adressdetails"])}
    }
  },
  "tag": {
    "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlt"])},
    "unpaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbezahlt"])},
    "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überfällig"])}
  },
  "referral": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helfen Sie anderen Unternehmern, Amplr zu entdecken, und verdienen Sie einen Rabatt von 200 Euro, wenn sie Kunde werden!"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die E-Mail-Adresse, den Namen und das Unternehmen der Person ein, die Sie einladen möchten. Wir erledigen den Rest!"])},
    "sector": {
      "horeca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastronomie"])},
      "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen"])},
      "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veranstaltungen"])},
      "retail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzelhandel"])}
    },
    "introduced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe Amplr dieser Person bereits vorgestellt"])}
  },
  "table": {
    "head": {
      "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktname"])}
    }
  },
  "legelEntities": {
    "belgium": {
      "nv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiengesellschaft (NV)"])},
      "bv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesellschaft mit beschränkter Haftung (BV)"])},
      "cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genossenschaft (CV)"])},
      "vzw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verein ohne Gewinnerzielungsabsicht (VZW)"])},
      "vof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offene Handelsgesellschaft (VOF)"])},
      "commv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommanditgesellschaft (Comm V)"])},
      "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerschaft"])},
      "ivzw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internationale Vereinigung ohne Gewinnerzielungsabsicht (IVZW)"])}
    },
    "netherland": {
      "e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzelunternehmen"])},
      "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerschaft"])},
      "v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verein"])},
      "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stiftung"])}
    },
    "france": {
      "sarl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesellschaft mit beschränkter Haftung (SARL)"])},
      "sas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinfachte Aktiengesellschaft (SAS)"])},
      "sci": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobiliengesellschaft"])},
      "sa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiengesellschaft (SA)"])},
      "scop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genossenschaft und Partizipationsgesellschaft"])}
    }
  },
  "topology": {
    "deviceDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerätedetails"])},
    "device": {
      "ports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht über die Switch-Ports"])},
      "portName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portname"])},
      "selectPortProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie das Portprofil"])}
    },
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Bearbeiten eines Geräts kann schwerwiegende Auswirkungen auf den Betrieb Ihres Netzwerks haben. Bitte seien Sie vorsichtig beim Ändern von Parametern."])}
  },
  "device": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerätename"])},
    "uptime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betriebszeit des Geräts"])},
    "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerät ist offline"])},
    "cellular": {
      "plmnError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbieter wird geladen"])}
    }
  },
  "title": {
    "extraHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Produkte"])}
  },
  "text": {
    "installationLog": {
      "extraHardware": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wurden zusätzliche Produkte platziert, die nicht im Vertrag enthalten sind? Finden Sie die Produkte und fügen Sie sie dann hinzu."])}
    }
  },
  "details": {
    "247": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24/7"])},
    "mailSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Support"])},
    "phoneSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon-Support"])},
    "businessHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supportzeiten: Mo bis Fr von 9 bis 17 Uhr"])},
    "businessHoursPlus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supportzeiten: Mo bis Fr von 8 bis 20 Uhr"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard-Zugriff"])},
    "dashboardBasic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basis-Dashboard-Zugriff"])},
    "supportQR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support-QR"])},
    "maxDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale Anzahl Geräte: "])},
    "brandedGuestWifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebrandetes Gäste-WLAN"])},
    "deviceUpdates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Geräte-Updates"])}
  },
  "successpage": {
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie diese Seite sehen können, funktioniert Ihre Internetverbindung."])},
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg!"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie ein AMPLR-Kunde?"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch kein Kunde und möchten mehr Infos?"])}
  },
  "toast": {
    "restored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederhergestellt"])},
    "exportStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export gestartet. Sie erhalten eine E-Mail, wenn der Export zum Download bereit ist."])}
  },
  "select": {
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
    "supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferanten"])}
  },
  "public": {
    "noDeviceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Systemgruppen definiert."])}
  },
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "past_due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überfällig"])},
  "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storniert"])},
  "connectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internetverbindung"])},
  "failover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failover"])},
  "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviceplan"])},
  "peplink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peplink-Lizenz"])},
  "serviceError": {
    "verifyProductBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt weicht von der Abrechnung ab. Überprüfen Sie den Standort und/oder die Abrechnung!"])},
    "installedButNotBilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienst installiert, aber nicht abgerechnet! Bitte Abrechnung anpassen."])},
    "billedButNotActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt abgerechnet, aber nicht am Standort aktiv. Bitte Standorteinstellungen überprüfen!"])}
  },
  "message": {
    "noSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort hat kein aktives Abonnement. Bitte überprüfen Sie dies mit der Buchhaltung."])},
    "sales": {
      "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufsbesuch hinzugefügt!"])}
    }
  },
  "sales": {
    "new": {
      "venueDetails": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veranstaltungsdetails"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die Veranstaltungsdetails an."])}
      },
      "contactDetails": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktdetails"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füllen Sie die Kontaktdetails aus"])}
      },
      "details": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veranstaltungsinformationen"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Informationen zur Veranstaltung"])}
      },
      "extra": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Informationen"])}
      },
      "generalInfo": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Informationen"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füllen Sie die allgemeinen Informationen aus"])}
      }
    }
  },
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
  "filter": {
    "sales": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
      "wantsOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchte Angebot"])},
      "followUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benötigt Nachverfolgung"])}
    }
  },
  "search": {
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ergebnisse! Bitte verwenden Sie einen anderen Suchbegriff."])}
  },
  "filters": {
    "hasNoPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Zahlungsmethode"])},
    "hasNoLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Standorte"])}
  },
  "customers": {
    "noAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Zugriffsrechte für diesen Kunden"])},
    "invoices": {
      "forceReload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungen erneut laden erzwingen"])}
    }
  },
  "main": {
    "referral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus"])}
  },
  "bonus": {
    "hero": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🎉 Empfehlen und sparen Sie bei Ihrer nächsten Rechnung!"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p class=\"mb-3\">Bei Amplr glauben wir, dass jedes Unternehmen das Recht auf eine zuverlässige Internetverbindung hat. Deshalb sind wir ständig bestrebt, unsere Dienstleistungen zu verbessern und unseren Kundenstamm zu erweitern. </p><p class=\"mb-3\">Als geschätzter Amplr-Kunde wissen wir, dass Ihr einzigartiges Netzwerk und Ihre Verbindungen von unschätzbarem Wert sind. </p><p>Daher laden wir Sie ein, mit uns neue Standorte zu erkunden, die wie Ihre von unseren Lösungen profitieren können, durch das Amplr-Empfehlungsprogramm.</p>"])},
      "howDoesItWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie funktioniert es?"])},
      "howDoesItWorkBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennen Sie Unternehmen oder Einzelpersonen in Ihrem Netzwerk, die an den Dienstleistungen von Amplr interessiert sein könnten? Wenn ja, können Sie sie jetzt ganz einfach an uns weiterleiten. Alles, was Sie tun müssen, ist den Kunden für unsere Lösungen zu begeistern. Wir kümmern uns um den Rest!"])},
      "whatsInItForYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um Ihnen für Ihre Bemühungen zu danken, haben wir ein Belohnungsprogramm für erfolgreiche Empfehlungen eingerichtet. Wenn Ihr Lead einen Vertrag mit Amplr abschließt, erhalten Sie einen Rabatt von 200 € auf Ihre nächste Rechnung. Außerdem erhält Ihr geworbener Lead einen attraktiven Rabatt auf die einmalige Einrichtungsgebühr."])},
      "whatsInIt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was ist für Sie drin?"])}
    },
    "page": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfehlungsdetails"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füllen Sie das Formular aus, um ein Unternehmen zu empfehlen, das Amplr für die Konnektivität nutzen kann."])}
    }
  },
  "signal": {
    "excellent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgezeichnet"])},
    "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gut"])},
    "fair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fair"])},
    "poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlecht"])},
    "unstable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instabil"])}
  },
  "clients": {
    "table": {
      "tenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mieter"])}
    }
  },
  "unifios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unifi OS"])},
  "omada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TP-Link Omada"])},
  "unificloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud-Controller"])},
  "controller": {
    "loginSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super! Wir können Ihren Controller erreichen."])},
    "connectionFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung zu Ihrem Controller fehlgeschlagen. Bitte stellen Sie sicher, dass Ihr Controller von außerhalb des Netzwerks erreichbar ist."])},
    "wrongCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir können keinen Anmeldeaufruf an den Controller senden. Bitte stellen Sie sicher, dass Benutzername und Passwort korrekt sind."])},
    "createSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller erfolgreich hinzugefügt"])}
  },
  "branding": {
    "settings": {
      "form": {
        "headerImage": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopfzeilenbild"])}
        },
        "logo": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])}
        }
      }
    },
    "mail": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalisierte Einstellungen für E-Mails"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalisieren Sie Ihre E-Mails, indem Sie benutzerdefinierte Farben, Kopfzeilenbilder und Logos verwenden."])}
    }
  },
  "image": {
    "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir verarbeiten Ihr Bild"])}
  },
  "deviceGroups": {
    "loadingDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle verbundenen Geräte werden geladen..."])}
  },
  "connectionLoss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internetverbindung fällt regelmäßig aus"])},
  "slowWifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langsames WLAN/Gast-WLAN"])},
  "posIssues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POS-Probleme (Tickets/Verbindung...)"])},
  "supportIssues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbieter-Support ist schlecht"])},
  "soundIssues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton stottert"])},
  "followUp": {
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot machen"])},
    "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anruf tätigen"])},
    "notNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht jetzt"])}
  },
  "copy": {
    "clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In die Zwischenablage kopieren"])}
  },
  "invalid": {
    "apiKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der API-Schlüssel scheint ungültig zu sein. Bitte überprüfen Sie den Schlüssel und versuchen Sie es erneut."])}
  },
  "app": {
    "dashboardTab": {
      "noDeviceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Gerätegruppen konfiguriert."])}
    }
  },
  "product": {
    "singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt"])},
    "notfound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt existiert nicht"])},
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt existiert nicht"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt aktualisiert"])}
  },
  "productModal": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktmodell nicht gefunden"])}
  },
  "productModel": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktmodell nicht gefunden"])}
  },
  "fluvius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fluvius"])},
  "errorCreatingOmadaLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Erstellen des Omada-Standorts. \nDie Datei ist nicht kompatibel. \nBitte stellen Sie sicher, dass Sie die richtige Datei (in den Konfigurationsparametern) verwenden, die zur Controller-Version passt."])}
}
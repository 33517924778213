<template>
  <div class="field" :class="{ 'is-fullwidth': fullwidth }">
    <label v-if="label" :for="id" class="label">
      <span :class="tooltip && !requiredField && !required ? 'mr-2' : ''">{{
        label
      }}</span>
      <sup
        v-if="required || requiredField"
        class="has-text-danger"
        :class="tooltip ? 'mr-2' : ''"
        >*</sup
      >
      <BasicTooltip v-if="tooltip" :content="tooltip" />
    </label>
    <div
      :class="[
        {
          'has-icons-left': icon,
          'has-icons-right': type === 'password',
        },
        vClass,
        isLoading ? 'is-loading' : '',
      ]"
      class="control"
    >
      <input
        :id="id"
        class="input"
        :type="type"
        :value="modelValue"
        :placeholder="placeholder"
        :required="required"
        :disabled="disabled"
        :class="{ 'is-danger': errors?.length }"
        autocomplete="on"
        :min="min"
        :max="max"
        :name="name"
        @input="$emit('update:modelValue', $event.target.value)"
        @keyup="$emit('keyup', $event.target.value)"
        @keyup.enter="$emit('keyupEnter', $event.target.value)"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        ref="input"
      />
      <span v-if="icon" class="icon is-small is-left">
        <font-awesome-icon :icon="['fad', icon]" />
      </span>
      <span
        v-if="type == 'password' && !disableWatch"
        class="input-toggle-password is-clickable"
        @click="(e) => (togglePassword(e), (passwordToggle = !passwordToggle))"
      >
        <font-awesome-icon v-if="passwordToggle" :icon="['fad', 'eye']" />
        <font-awesome-icon
          v-if="!passwordToggle"
          :icon="['fad', 'eye-slash']"
        />
      </span>
    </div>
  </div>
  <div v-for="error of errors" :key="error.$uid">
    <div class="notification is-danger mb-3">
      {{ error.$message ?? error }}
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    tooltip: {
      default: '',
      type: String,
    },

    requiredField: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      default: '',
      type: String,
    },
    icon: {
      default: '',
      type: String,
    },
    type: {
      default: '',
      type: String,
    },
    required: {
      default: false,
      type: Boolean,
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      default: false,
      type: Boolean,
    },
    vClass: {
      default: '',
      type: String,
    },
    onChangeEmit: {
      default: '',
      type: String,
    },
    label: {
      default: '',
      type: String,
    },
    errors: {
      default: () => [],
      type: Array,
    },
    min: {
      default: '',
      type: String,
    },
    max: {
      default: '',
      type: String,
    },
    name: {
      default: '',
      type: String,
    },
    disableWatch: {
      default: false,
      type: Boolean,
    },
    fullwidth: {
      default: false,
      type: Boolean,
    },
  },
  emits: ['update:modelValue', 'keyup', 'focus', 'blur'],
  data() {
    return {
      passwordToggle: false,
      id: uuidv4(),
    };
  },
  methods: {
    togglePassword(e) {
      if (e.target.closest('div').firstChild.type === 'text') {
        e.target.closest('div').firstChild.type = 'password';
      } else {
        e.target.closest('div').firstChild.type = 'text';
      }
    },
    focus() {
      this.$nextTick(() => this.$refs.input.focus());
    },
  },
};
</script>

<style lang="scss">
.input-toggle-password {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  padding: 8px;
}
input[type='number']::-webkit-inner-spin-button {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: textfield;
}
</style>
